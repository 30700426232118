@font-face {
  font-family: 'CustomFont';
  src: url('./Outfit-VariableFont_wght.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
html {
  overflow-x: hidden;
  
  
  
}
body {
  font-family: 'CustomFont', sans-serif;
  width: 100%; 
  overflow-x: hidden;
  margin: 0 !important;
  padding: 0;
}

.App {
  text-align: center;
}

.header-footer-image {
  width: 100%;
  height: auto;
}

.App-header,
.App-footer {
  background-color: white;
}

.App-header {
  position: relative;
  text-align: center;
}

.App-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(to top, white, transparent);
}

.header-title {
  position: absolute;
  top: 20px;
  left: 3%;
  font-size: 90px;
  color: white;
  margin: 0;
}

.header-buttons {
  position: absolute;
  top: 30%;
  left: 3%;
  display: flex;
  gap: 10px;
}

.header-buttons button {
  background: transparent;
  border: 2px solid #4C4C4B;
  color: #4C4C4B;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s;
}

.header-buttons button:hover {
  background: rgba(76, 76, 75, 0.1);
}

.header-subtitle {
  position: absolute;
  top: 45%;
  left: 3%;
  font-size: 100px;
  color: #4C4C4B;
  margin: 0;
  text-align: left;
  white-space: nowrap;
}
#reg {
  position: relative;
}
#reg button {
  transform: translateX(-50%);
  position: absolute;
  background: #39ff14; /* Neon green */
  border: 5px solid #39ff14; /* Neon green border */
  color: #4C4C4B;
  padding: 25px 50px; /* Increased padding for a bigger button */
  font-size: 24px; /* Larger font size */
  font-weight: bold; /* Bold text */
  border-radius: 20px;
  cursor: pointer;
  transition: background 0.3s, box-shadow 0.3s;
  left: 80%;
  top: 35%;
  box-shadow: 0 0 40px #39ff14, 0 0 80px #39ff14, 0 0 120px #39ff14;
  animation: neon-glow 0.7s infinite alternate; /* Faster pulsating */
}

@keyframes neon-glow {
  0% {
    box-shadow: 0 0 10px #39ff14, 0 0 30px #39ff14, 0 0 50px #39ff14;
  }
  50% {
    box-shadow: 0 0 60px #39ff14, 0 0 120px #39ff14, 0 0 180px #39ff14;
  }
  100% {
    box-shadow: 0 0 80px #39ff14, 0 0 160px #39ff14, 0 0 240px #39ff14;
  }
}

#reg button:hover {
  animation: none; /* Stop the animation on hover */
  box-shadow: 0 0 80px #39ff14, 0 0 160px #39ff14, 0 0 240px #39ff14; /* Maintain the max glow on hover */
}






.App-content {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section {
  width: 100%;
  padding: 20px;
  text-align: center;
}

.section-image {
  display: block;
  margin: 20px auto;
  width: 100%;
  height: auto;
}
#ourteam {
  position: relative; /* This ensures that all absolutely positioned elements inside are relative to this section */
  text-align: center;
  margin-bottom: 40px;
}

.section-image-linkedin {
  width: 100%; /* Full width of the image */
  height: auto;
}

.linkedin-button0,
.linkedin-button1,
.linkedin-button2,
.linkedin-button3,
.linkedin-button4,
.linkedin-button5,
.linkedin-button6,
.linkedin-button7,
.linkedin-button8 {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: transparent; /* Optional: Background for visibility */
  
  z-index: 10; /* Ensures buttons appear above the image */
  border: none;
  cursor: pointer;
  transform: translateX(-50%);
}

/* Adjust the positions based on the image layout */
.linkedin-button0 {
  top: 19.8%; /* Adjust this value to position correctly over the image */
  left: 20.5%; /* Adjust to correct horizontal alignment */
}

.linkedin-button1 {
  top: 19.5%;
  left: 65.5%;
}

.linkedin-button2 {
  top: 36.50%;
  left:20.5%;
}

.linkedin-button3 {
  top: 36.5%;
  left: 65.5%;
}

.linkedin-button4 {
  top: 53.3%;
  left: 20.5%;
}

.linkedin-button5 {
  top: 53.3%;
  left: 65.5%;
}

.linkedin-button6 {
  top: 70%;
  left: 20.5%;
}

.linkedin-button7 {
  top: 70%;
  left: 65.5%;
}

.linkedin-button8 {
  top: 87.2%;
  left: 20.5%;
}

.App-footer {
  position: relative;
  background-color: white;
  
}

.App-footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50px;
  background: linear-gradient(to bottom, white, transparent);
}

.footer-title {
  position: absolute;
  top: 20%;
  left: 20%;  
  font-size: 50px;
  color: #4C4C4B;
}
.logoimg{
  position: absolute;
  top: 45%;
  left: 75%;
  transform: translate(-50%, -50%);
  width: 70;
  height: 70%;
  
}
.signup-button {
  position: absolute;
  top: 60%;
  left: 20%;
  padding: 8px 16px;
  font-size: 16px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  cursor: pointer;
}

.signup-button:hover {
  background-color: #777;
  border-color: #555;
}

.footer-image {
  width: 100%;
  height: 300px;
}

.social-icons {
  position: absolute;
  top: 50%;
  left: 70%;
  display: flex;
  justify-content: center;
  gap: 25px;
}

.icon {
  font-size: 28px;
  color: #333;
  transition: color 0.3s ease;
}

.icon:hover {
  color: #777;
}

/* Custom Dropdown Styling */
.custom-dropdown {
  position: relative;
  display: none; /* Hidden by default */
  z-index: 10; /* Ensure dropdown is on top of other elements */
}

.dropdown-btn {
  background: transparent;
  border: 2px solid #4C4C4B;
  color: #4C4C4B;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background 0.3s;
  z-index: 10; /* Ensure button is also on top */
}

.dropdown-btn:hover {
  background: rgba(76, 76, 75, 0.1);
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: transparent;
  min-width: 160px;
  background: transparent;
  border: 2px solid #4C4C4B;
  color: #4C4C4B;
  border: 2px solid #4C4C4B;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-item {
  color: #4C4C4B;
  border: 1px solid #4C4C4B;
  padding: 12px 16px;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid rgba(76, 76, 75, 0.1);
  transition: background 0.3s;
}

.dropdown-item:hover {
  background-color: rgba(76, 76, 75, 0.1);
}

.dropdown-item:last-child {
  border-bottom: none;
}

@keyframes shake {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
}

@media (min-width: 860px) and (max-width: 1244px) {
  .custom-dropdown {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  #menu {
    color: white;
    font-size: 30px;
  }

  #menu:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }

  #reg button {
    padding: 20px 35px; /* Reduce padding for smaller size */
    font-size: 16px; /* Decrease font size */
    border: 3px solid #39ff14; /* Adjust border size */
    top: 35%; /* Adjust positioning to fit smaller screens */
    left: 72%; /* Center horizontally */
    transform: translateX(-50%); /* Center the button on smaller screens */
    box-shadow: 0 0 20px #39ff14, 0 0 40px #39ff14, 0 0 60px #39ff14; /* Reduce shadow size */
  }
  .header-title {
    font-size: 75px;
    top: 15px;
    left: 2%;
  }

  .header-subtitle {
    font-size: 75px;
    top: 40%;
    left: 2%;
  }

  .header-buttons {
    display: none;
  }

  .footer-title {
    font-size: 40px;
    left: 15%;
  }

  .signup-button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .icon {
    font-size: 24px;
  }

  .social-icons {
    left: 60%;
    top: 40%;
  }
  
}


@media (max-width: 860px) {
  .custom-dropdown {
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  #menu {
    color: white;
    font-size: 30px;
  }
  #menu:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }
  #reg button {
    padding: 20px 35px; /* Reduce padding for smaller size */
    font-size: 16px; /* Decrease font size */
    border: 3px solid #39ff14; /* Adjust border size */
    top: 30%; /* Adjust positioning to fit smaller screens */
    left: 72%; /* Center horizontally */
    transform: translateX(-50%); /* Center the button on smaller screens */
    box-shadow: 0 0 20px #39ff14, 0 0 40px #39ff14, 0 0 60px #39ff14; /* Reduce shadow size */
  }
  .dropdown-btn {
    padding: 5px 10px;
  }

  .header-title {
    font-size: 45px;
    top: 15px;
    left: 2%;
  }

  .header-subtitle {
    font-size: 45px;
    top: 40%;
    left: 2%;
  }

  .header-buttons {
    display: none;
  }
  .linkedin-button0,
  .linkedin-button1,
  .linkedin-button2,
  .linkedin-button3,
  .linkedin-button4,
  .linkedin-button5,
  .linkedin-button6,
  .linkedin-button7,
  .linkedin-button8 {
    width: 20px; /* Adjusted width for smaller screens */
    height: 20px; /* Adjusted height for smaller screens */
  }
  .linkedin-button0 {
    top: 20.5%; /* Adjusted position for smaller screens */
    left: 22%; /* Adjusted position for smaller screens */
  }
  .linkedin-button1 {
    top: 20.5%;
    left: 65%; /* Adjusted position for smaller screens */
  }
  .linkedin-button2 {
    top: 36.5%;
    left: 22%; /* Adjusted position for smaller screens */
  }
  .linkedin-button3 {
    top: 36.5%;
    left: 65%; /* Adjusted position for smaller screens */
  }
  .linkedin-button4 {
    top: 52.5%;
    left: 22%; /* Adjusted position for smaller screens */
  }
  .linkedin-button5 {
    top: 52.5%;
    left: 65%; /* Adjusted position for smaller screens */
  }
  .linkedin-button6 {
    top: 68.7%;
    left: 22%; /* Adjusted position for smaller screens */
  }
  .linkedin-button7 {
    top: 68.7%;
    left: 65%; /* Adjusted position for smaller screens */
  }
  .linkedin-button8 {
    top: 85%;
    left: 22%; /* Adjusted position for smaller screens */
  }

  .footer-title {
    font-size: 40px;
    left: 15%;
  }

  .signup-button {
    font-size: 14px;
    padding: 6px 12px;
  }

  .icon {
    font-size: 24px;
  }

  .social-icons {
    left: 60%;
    top: 40%;
  }
}




@media (max-width: 480px) {
    body{
      margin: 0;
      padding: 0;
    }
  
    .header-title {
        font-size: 30px;
        top: 10px;
    }
    #menu {
      color: white;
      font-size: 30px;
    }
    #menu:hover {
      animation: shake 0.5s;
      animation-iteration-count: infinite;
    }
  .linkedin-button0,
  .linkedin-button1,
  .linkedin-button2,
  .linkedin-button3,
  .linkedin-button4,
  .linkedin-button5,
  .linkedin-button6,
  .linkedin-button7,
  .linkedin-button8 {
    width: 15px; /* Adjusted width for smaller screens */
    height: 15px; /* Adjusted height for smaller screens */
  }
    
    .linkedin-button0 {
      top: 21%;
      left: 22.4%;
    }
    .linkedin-button1 {
      top: 21%;
      left: 65%;
    }
    .linkedin-button2 {
      top: 37%;
      left: 22.4%;
    }
    .linkedin-button3 {
      top: 37%;
      left: 64.5%;
    }
    .linkedin-button4 {
      top: 52.5%;
      left: 22.4%;
    }
    .linkedin-button5 {
      top: 52.5%;
      left: 64.5%;
    }
    .linkedin-button6 {
      top: 68%;
      left: 22.4%;
    }
    .linkedin-button7 {
      top: 68%;
      left: 64.5%;
    }
    .linkedin-button8 {
      top: 84%;
      left: 22.4%;
    }
  
    .header-subtitle {
        font-size: 30px;
        top: 35%;
    }

    .footer-title {
        font-size: 25px;
    }

    .signup-button {
        font-size: 12px;
    }

    .icon {
        font-size: 20px;
    }

    .footer-image {
      width: 100%;
      height: 200px;
    }
    .footer-title{
      top: 20%;
    }
    #reg button {
      padding: 10px 20px; /* Reduce padding for smaller size */
      font-size: 16px; /* Decrease font size */
      border: 3px solid #39ff14; /* Adjust border size */
      top: 35%; /* Adjust positioning to fit smaller screens */
      left: 72%; /* Center horizontally */
      transform: translateX(-50%); /* Center the button on smaller screens */
      box-shadow: 0 0 20px #39ff14, 0 0 40px #39ff14, 0 0 60px #39ff14; /* Reduce shadow size */
    }
    #reg {
      margin-bottom: -60px;
    }
    

    

    .social-icons {
        gap: 15px;
        top: 75%; /* Remove the top positioning */
        left: 35% !important;
        transform: translateX(-50%); /* Center horizontally */
        position: absolute;
         /* Add space between signup button and social icons */
        
    }

    .signup-button {
        top: 50%;
    }
}
